<div class="wrapper">
    <div class="sidebar" data-color="info" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
    </div>
</div>

<div id="loadingComponent" class="loading-shade h-100 w-100 position-absolute" style="display: none; z-index: 6666">
    <mat-spinner></mat-spinner>
</div>
