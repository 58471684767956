<div class="logo">
  <img class="justify-content-center mw-100 px-3" src="assets/img/calouros_logo.png">
</div>
<div class="sidebar-wrapper py-3 d-flex">
  <ul class="nav d-flex align-items-start flex-grow-1 flex-shrink-1 flex-column">
    <li *ngFor="let menuItem of menuItems" class="{{ menuItem.class }} nav-item w-100" routerLinkActive="active">
      <a [routerLink]="[menuItem.path]" (click)="onClickToNavigate(menuItem.path)" class="nav-link">
        <i class="material-icons">{{ menuItem.icon }}</i>
        <p>{{ menuItem.title }}</p>
      </a>
    </li>
    <img class="justify-content-center mt-auto mw-100 px-3" src="../../../assets/img/liga_logo.svg">
  </ul>
</div>
