//#region Imports

import { Component} from '@angular/core';

//#endregion

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

/**
 * A classe principal do aplicativo
 */
export class AppComponent {}
