export const environment = {
  production: false,
  app_name: 'LIGA',
  icon_name: './assets/img/calouros_logo.png',
  api_endpoint: 'https://api-dev.vemprafacens-onboarding.ligafacens.com',
  config: {
    redirectToWhenAuthenticated: '/dashboard/users',
    redirectToWhenUnauthenticated: '/login',
  },
};
